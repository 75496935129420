import React from 'react';
import "../../theme.css"
import "./footer.css"

const Footer = () => {
    return (
        <div>
            <div className="Footer">
                <div className="footer-about-me">
                    <h4 className="footer-title">About me</h4>
                    <p>Enthusiastic and hardworking student, I was born in Bordeaux, France, and
                followed an intensive two-year post-baccalaureate course in mathematics and physics designed to prepare the « Grandes Ecoles » competitive exams...</p>
                    <p>I was then selected to study at Grenoble-INP : ENSIMAG, where
                    I completed a Master degree in Maths and Computer Science, with a specialization
                in Software Development.</p>
                    <p>I am currently working as an iOS developer.</p>
                </div>
                <div className="footer-links">
                    <h4 className="footer-title"><a href="mailto:thomas.esterlin@grenoble-inp.org">Contact me</a></h4>
                    <h4 className="footer-title"><a href="https://www.linkedin.com/in/thomas-esterlin">My LinkedIn</a></h4>
                    <h4 className="footer-signature">Thomas<br/>Esterlin</h4>
                </div>
            </div>
            <div className="Copyrights">
                © 2020-2024 | Thomas Esterlin
            </div>
        </div>
    );
}

export default Footer;