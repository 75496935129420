import React from 'react';
import "./gameboygif.css"

const GameBoyGif = (props) => {
    return (
        <div className="GameBoyGif">
            <img src={props.image} alt="" />
            <h4>{props.legend}</h4>
        </div>
    );
}

export default GameBoyGif;