export const lightTheme = {
    name: 'lightTheme',
    body: '#F3F2F7',
    text: '#33332d',
}

export const darkTheme = {
    name: 'darkTheme',
    body: '#1c1c1e',
    text: '#F3F2F7',
}