import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectHeader from '../components/ProjectHeader';
import { Link } from "react-router-dom";

import "./gameboy.css"

function GameboyChapterLink(props) {
  return (
    <div className="GameboyChapterLink">
      <Link to={props.chapterPage}>
            {props.chapterTitle}
      </Link>
    </div>
  );
}

function GameboyHome(props) {
  return (
    <div className="GameboyHome">
      <Header theme={props.theme} toggleTheme={props.toggleTheme} />
      <ProjectHeader title="Programming Games on Game Boy" partners="Antoine Flichy, Pierre Millot" duration="Still underway" languages="C, ASM" />

      <div className="GameBoy-desc">
        <p>Apart from my school projects, I am truly passionate about developing old school games, and the Nintendo
        Game Boy was the very first console I owned as a child. So about a year ago, I decided to delve into the
        workings of this machine to try to develop games on it.
        </p>
        <p>
          On this page I am going to show some examples of what we achieved with two friends from ENSIMAG, using
          reverse engineering techniques to create a game very inspired by <a href="https://fr.wikipedia.org/wiki/The_Legend_of_Zelda:_Link%27s_Awakening">The Legend Of Zelda :
          Link's Awakening</a>, a video-game which was released by Nintendo in 1993.
        </p>
        <p>
          Let's quickly see !
        </p>

      <GameboyChapterLink chapterTitle="Part A" chapterPage="/projects/gameboy-a"/>
      <GameboyChapterLink chapterTitle="Part B" chapterPage="/projects/gameboy-b"/>
      <GameboyChapterLink chapterTitle="Part C" chapterPage="/projects/gameboy-c"/>
      </div>


      <Footer />
    </div>
  );
}

export default GameboyHome;