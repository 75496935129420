import React from "react";
import { Link } from "react-router-dom";
import "./navmenu.css";

const NavMenu = (props) => {
  return (
    <div className="NavMenu">
      <div className="NavMenuItemsContainer">
        <div className="NavMenuItem">
          <Link to="/projects/gameboy">
            <button className={"navmenu-button gameboy-nav " + props.gameboy}>
              _ Gameboy Project
            </button>
          </Link>
        </div>
        <div className="NavMenuItem">
          <Link to="/projects">
            <button className={"navmenu-button school-nav " + props.school}>
              _ School Projects
            </button>
          </Link>
        </div>
        <div className="NavMenuItem">
          <Link to="/music">
            <button className={"navmenu-button music-nav " + props.music}>
              _ Music & Me
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
