import React from 'react';
import "./projectheader.css"

const ProjectHeader = (props) => {
    return (
        <div className="ProjectHeader">
            <div className="title">
                <h1>{props.title}</h1>
            </div>
            <div className="project-info">
                <h4>Project Partners : <span style={{ fontWeight: "normal" }}>{props.partners}</span></h4>
                <h4>Project Duration : <span style={{ fontWeight: "normal" }}>{props.duration}</span></h4>
                <h4>Languages Used : <span style={{ fontWeight: "normal" }}>{props.languages}</span></h4>
            </div>
        </div>
    );
}

export default ProjectHeader;