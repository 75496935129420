import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectImage from '../components/ProjectImage';
import ProjectHeader from '../components/ProjectHeader';
import './page-style-sheet.css'

import java1 from './projects-meta/java1.gif';
import java2 from './projects-meta/java2.gif';
import java3 from './projects-meta/java3.gif';
import java4 from './projects-meta/java4.gif';



const MultiagentSystems = (props) => {
    return (
        <div className="MultiagentSystems">
            <Header theme={props.theme} toggleTheme={props.toggleTheme} />
            <ProjectHeader title="Multi-Agent Systems in Java" partners="Louis Godron & Pierre Millot" duration="Several weeks" languages="Java" />

            <div className="project-desc">
                <p>
                    The aim of this project was to develop an application (in Java) simulating multiagent systems with graphics.
                    First, two cellular automaton systems were developed : Conway's Game Of Life and an 'immigration game'.
                    Next, a self-organized swarm movement system was studied : the model of Boids.
                </p>
                <p>This project was mainly thought to improve skills in Object-Oriented Programming.</p>

                <h2>A first simulator : Balls</h2>
                <p>
                    The very first system we developed was a basic simulation of balls bouncing in a space.
                    The idea was to describe a ball with coordinates and implement every physic law we could think of
                    to improve its behaviour render in a graphic interface. For example, we made the ball bouncing on
                    the ground and on the walls, we simulated Newton's Second Law of Motion by adding acceleration
                    related to the force of weight, and we made it lose speed when rubbing against a surface.
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={java1} legend="SIMULATION OF BALLS" />
                </div>

                <h2>Conway's Game Of Life</h2>
                <div className="ImageFlexbox">
                    <ProjectImage image={java2} legend="GAME OF LIFE" />
                </div>

                <h2>Game Of Immigration</h2>
                <div className="ImageFlexbox">
                    <ProjectImage image={java3} legend="GAME OF IMMIGRATION" />
                </div>

                <h2>The model of Boids</h2>
                <div className="ImageFlexbox">
                    <ProjectImage image={java4} legend="BOIDS SIMULATION" />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default MultiagentSystems;