import React from 'react';
import "./projectimage.css"

const ProjectImage = (props) => {
    return (
        <div className={"ProjectImage ".concat(props.imgClass)} >
            <img src={props.image} alt="" />
            <h4>{props.legend}</h4>
        </div>
    );
}

export default ProjectImage;