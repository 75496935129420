import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectImage from '../components/ProjectImage';
import ProjectHeader from '../components/ProjectHeader';
import './page-style-sheet.css'


import lg1 from './projects-meta/LG/lg1.png';
import lg2 from './projects-meta/LG/lg2.png';
import lg3 from './projects-meta/LG/lg3.png';
import lg4 from './projects-meta/LG/lg4.png';
import lg5 from './projects-meta/LG/lg5.png';
//import lg6 from './projects-meta/LG/lg6.png';
import lg7 from './projects-meta/LG/lg7.png';
import lg8 from './projects-meta/LG/lg8.png';
import lg9 from './projects-meta/LG/lg9.png';
import lg10 from './projects-meta/LG/lg10.png';
import lg11 from './projects-meta/LG/lg11.png';
import lg12 from './projects-meta/LG/lg12.png';
import lg13 from './projects-meta/LG/lg13.png';
import lg14 from './projects-meta/LG/lg14.png';
import lg15 from './projects-meta/LG/lg15.png';
//import lg16 from './projects-meta/LG/lg16.png';
//import lg17 from './projects-meta/LG/lg17.png';
import lg18 from './projects-meta/LG/lg18.png';
//import lg19 from './projects-meta/LG/lg19.png';
import lg20 from './projects-meta/LG/lg20.png';
import lg21 from './projects-meta/LG/lg21.png';
//import lg22 from './projects-meta/LG/lg22.png';
import lg23 from './projects-meta/LG/lg23.png';
//import lg24 from './projects-meta/LG/lg24.png';
//import lg25 from './projects-meta/LG/lg25.png';
//import lg26 from './projects-meta/LG/lg26.png';
import lg27 from './projects-meta/LG/lg27.png';




const LoupsGarous = (props) => {
    return (
        <div className="LoupsGarous">
            <Header theme={props.theme} toggleTheme={props.toggleTheme}/>
            <ProjectHeader title="WEB App Project - Loups Garous" partners="Pierre Millot, Mathieu Valliccioni, Titouan Jouffray" duration="10 weeks" languages="HTML, CSS, JS" />

            <div className="project-desc">
                <p>
                    The objective of this project was to create a web app which was an online game version of the French role play
                    "Loups-Garous". It is better known in English-speaking countries as "Mafia". The development of this web app  was very instructive as
                    as plenty of features had to be implemented, and some of them were pretty difficult.
                </p>

                <h2>The Homepage</h2>
                <ProjectImage image={lg1} legend="" imgClass="imgLG" />
                <p>
                    The homepage is quite classic. There's a menu with 3 links : the homepage, the rules and the list of playable characters.
                    On the left we find a summary of the rules, in the middle a form to sign in and on the right another form to register.
                </p>
                <h2>The Rules</h2>
                <ProjectImage image={lg2} legend="" imgClass="imgLG" />
                <p>
                    The rules page is also very simple, it only contains scrollable text that indicates the rules of "Loups-Garous".
                </p>
                <h2>The Character List</h2>
                <ProjectImage image={lg3} legend="" imgClass="imgLG" />
                <p>
                    The characters page is also simple : On the right stands a panel which give the name and the explanations of a role according to
                    the card you hover with your mouse on the left of the screen.
                </p>
                <ProjectImage image={lg4} legend="" imgClass="imgLG" />
                <p>
                    Indeed you can hover every card on the left to display the information you need on a specific character.
                </p>
                <h2>The Lobby</h2>
                <ProjectImage image={lg5} legend="" imgClass="imgLG" />
                <p>
                    When we log in, we directly come in a lobby. That is where we will be able to join friends or send invites to other people. Let's see how we
                    can add a friend. On the top-right of the screen, we can hover our avatar and our pseudo to access the "My Friends" section. We can also modify
                    parameters, or log out from here.
                </p>
                <h2>Friends Management</h2>
                <ProjectImage image={lg7} legend="" imgClass="imgLG" />
                <p>
                    In our app, we can add friends that will be able to join our lobby, or that we will be able to join. To add a friend, you simply have to hover the
                    "My Friends" part in the dropdown menu on the top-right, then click on "Add/Invite Friends" and search for your friend's pseudo. Then you
                    have to click on "ask".
                </p>
                <ProjectImage image={lg8} legend="" imgClass="imgLG" />
                <p>
                    The search bar offers auto-completion !
                </p>
                <ProjectImage image={lg9} legend="" imgClass="imgLG" />
                <p>
                    We implemented validation when adding a friend.
                </p>
                <ProjectImage image={lg10} legend="" imgClass="imgLG" />
                <p>
                    The friends page is updated.
                </p>
                <ProjectImage image={lg11} legend="" imgClass="imgLG" />
                <p>
                    You can accept or deny any friend request.
                </p>
                <ProjectImage image={lg12} legend="" imgClass="imgLG" />
                <p>
                    When you accept a request, the page is also updated.
                </p>
                <ProjectImage image={lg13} legend="" imgClass="imgLG" />
                <p>
                    Now, your friends that are online appear in your lobby on the left of the screen. You can invite them in your lobby by
                    clicking on "Invite".
                </p>
                <h2>Game Settings</h2>
                <ProjectImage image={lg14} legend="" imgClass="imgLG" />
                <p>
                    The host of the lobby (the one with the little crown next to his pseudo) can modify the settings of the game by clicking on the
                    little gearwheel on the right of the screen. He can also click on the little card to choose which roles to add to the game.
                    Everyone can click on the "QUIT" button to leave the lobby. Once everyone is ready and the settings are correct, the host can click
                    on the "LAUNCH GAME" button to start the game, everyone will be redirected towards the
                    game page.
                </p>
                <ProjectImage image={lg15} legend="" imgClass="imgLG" />

                <h2>Game Screenshots :</h2>
                <ProjectImage image={lg18} legend="" imgClass="imgLG" />
                <ProjectImage image={lg23} legend="" imgClass="imgLG" />
                <ProjectImage image={lg20} legend="" imgClass="imgLG" />
                <ProjectImage image={lg21} legend="" imgClass="imgLG" />
                <ProjectImage image={lg27} legend="" imgClass="imgLG" />
            </div>
            <Footer />
        </div>
    );
}

export default LoupsGarous;