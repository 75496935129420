import React from 'react';
import ProjectBox from './ProjectBox';
import "./projectboxes.css"


const ProjectBoxes = (props) => {
    return (
        <div className="ProjectBoxes">
            {props.projects.map(project =>
                <ProjectBox key={project.id} title={project.title} image={project.image} link={project.link} />
            )}
        </div>
    );
}

export default ProjectBoxes;