import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import NavMenu from "./NavMenu";

const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  React.useEffect(() => {
    document.body.style.overflowY = showMenu ? "hidden" : "auto";
  }, [showMenu]);

  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="Header-container">
      <div className="Header">
        <div className="header-name">
          <Link to="/">
            <h2>Thomas Esterlin</h2>
          </Link>
        </div>
        <div
          className={`collpased-nav-bar ${showMenu ? "show" : "hide"}`}
          id="collpased-nav-bar"
        >
          <NavMenu />
        </div>
        <button
          className={
            `header-button ${showMenu ? "show" : "hide"} ` + props.home
          }
          onClick={() => handleClick()}
        >
        </button>
      </div>
    </div>
  );
};

export default Header;
