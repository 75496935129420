import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Projects from './pages/Projects';
import GameboyHome from './pages/gameboy/GameboyHome';
import Music from './pages/Music';
import Steganography from './pages/projects-pages/Steganography'
import ReasearchConnected from './pages/projects-pages/ResearchConnected';
import MultiagentSystems from './pages/projects-pages/MultiagentSystems';
import Graphics from './pages/projects-pages/Graphics'
import LoupsGarous from './pages/projects-pages/LoupsGarous'
import Cod from './pages/projects-pages/Cod';
import {GameboyPartA, GameboyPartB, GameboyPartC} from './pages/gameboy/GameboyPart';


const Main = (props) => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={() => <Home theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects' component={() => <Projects theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/gameboy' component={() => <GameboyHome theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/gameboy-a' component={() => <GameboyPartA theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/gameboy-b' component={() => <GameboyPartB theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/gameboy-c' component={() => <GameboyPartC theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/music' component={() => <Music theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/steganography' component={() => <Steganography theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/research-connected' component={() => <ReasearchConnected theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/ma-systems' component={() => <MultiagentSystems theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/3d-graphics' component={() => <Graphics theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/web-lg' component={() => <LoupsGarous theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
      <Route exact path='/projects/cod' component={() => <Cod theme={props.theme} toggleTheme={props.toggleTheme} />}></Route>
    </Switch>
  );
}

export default Main;