import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectImage from '../components/ProjectImage';
import ProjectHeader from '../components/ProjectHeader';
import './page-style-sheet.css'

import graphics1 from './projects-meta/graphics1.png';
import graphics2 from './projects-meta/graphics2.png';
import graphics3 from './projects-meta/graphics3.png';
import graphics4 from './projects-meta/graphics4.png';
import graphics5 from './projects-meta/graphics5.gif';
import graphics6 from './projects-meta/graphics6.png';
import graphics7 from './projects-meta/graphics7.gif';




const Graphics = (props) => {
    return (
        <div className="Graphics">
            <Header theme={props.theme} toggleTheme={props.toggleTheme} />
            <ProjectHeader title="3D Graphics - Sea and Underwater" partners="Antoine Flichy, Mathieu Valliccioni" duration="8 weeks" languages="Python, OpenGL" />

            <div className="project-desc">
                <p>
                    This project was made during a course named 3D Graphics. The theme of the project was "Sea and Underwater"
                    and our we were free to be as creative as possible for the elements displayed.
                    We had the idea to recreate a level from the Nintendo video-game Super Mario 64,
                    because we are very interested in old-school video games programming, and this
                    particular game was one of the first to be developed in 3D. We tried to reproduce
                    a small aquarium level, where Mario is swimming inside and whose goal is to catch
                    eight red pieces.
                </p>
                <p>The main objectives of this project were to take the notions of the course in hand,
                such as models, textures, lights, animations, skinnig, etc..., while
                programming something fun and playful.
                </p>

                <h2>Getting the models</h2>
                <p>
                    To start we took the level's models on a website called models-resource.com.
                    We then could render the aquarium, and create a skybase to make the illusion it was
                    in a 'real world'. We then found someone who had reproduced Mario's model and every of its animations from
                    the original game in Blender, and who shared them voluntarily.
                    <a href='https://www.youtube.com/user/aryoksini'> Here is a link to his YT channel. </a>
                    We then put Mario in the aquarium to start developing a moving system.
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={graphics1} legend="Mario's model from aryoksini" />
                </div>



                <h2>Moving Mario</h2>
                <p>
                    Moving Mario was quite simple using keyframes techniques. We gave Mario translation and rotation keyframes
                    according to the keyboard input (basically UP, DOWN, LEFT and RIGHT to rotate, and A to move forward).
                </p>
                <h2>Animating Mario</h2>
                <p>
                    Animating Mario was harder, because we had to think of a system to handle the animations changes properly.
                    We created an automaton and implemented it to our game. Only one animation was displayed at a time, and the next
                    animation to be displayed was decided by the automaton.
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={graphics2} legend="Mario's animations automaton" />
                    <ProjectImage image={graphics5} legend="Mario's animations rendered in our game" />
                </div>
                <h2>Creating a non-linear water surface</h2>
                <p>To create a non-linear water surface, we created a shader, and defined a grid to represent the surface.
                The vertices were given a different height according to time, and this difference of height was calculated randomly
                with a simplex noise. These movements of vertices give the impression of water moving and waves. To go further, we modified
                the color of the shader according to the height : very high points are colored in white to simulate foam.
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={graphics6} legend="Vertices defining the surface" />
                    <ProjectImage image={graphics3} legend="Water shader, vertices are here forming linear waves" />
                    <ProjectImage image={graphics4} legend="Upgraded water shader, with foam simulation, vertices are now more realistic thanks to simplex noise" />
                </div>

                <h2>Adjusting the Camera</h2>
                <p>We then decided to adjust the camera behind Mario to recreate the view we have in modern video-games. This is done
                by doing some operations on the view matrix.
                </p>
                <h2>Improving the Gameplay</h2>
                <p>To finish our project, we added some elements of gameplay : very basic hitbox to prevent Mario from getting out of
                the aquarium, red coins that can be caught by implementing 'point in a sphere' hitboxes, blue filter displayed
                on the screen when Mario is underwater, etc... Check out the little demo below !
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={graphics7} />
                </div>

            </div>


            <Footer />
        </div>
    );
}

export default Graphics;