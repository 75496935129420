import React from 'react';
import ReactPlayer from 'react-player';
import Header from './components/Header';
import Footer from './components/Footer';
import './projects-pages/page-style-sheet.css'
import './music.css'

import bends from '../meta/albums/bends.jpg'
import grace from '../meta/albums/grace.jpg'
import appetite from '../meta/albums/appetite.jpg'
import showbiz from '../meta/albums/showbiz.jpg'
import bh from '../meta/albums/b&h.jpg'
import ratm from '../meta/albums/ratm.jpg'
import thriller from '../meta/albums/thriller.jpg'
import okcomputer from '../meta/albums/okcomputer.jpg'
import continuum from '../meta/albums/continuum.jpg'
import dookie from '../meta/albums/dookie.jpg'
import enema from '../meta/albums/enema.jpg'
import adele from '../meta/albums/21.jpg'
import color from '../meta/albums/color.jpg'


const albums = [
  {
    title: 'Appetite For Destruction',
    imageSrc: appetite,
    year: "1987",
    hoverText: "",
  },
  {
    title: 'Grace',
    imageSrc: grace,
    year: "1994",
    hoverText: "",
  },
  {
    title: 'The Bends',
    imageSrc: bends,
    year: "1995",
    hoverText: "",
  },
  {
    title: 'Showbiz',
    imageSrc: showbiz,
    year: "1999",
    hoverText: "",
  },
  {
    title: 'Black Holes & Revelations',
    imageSrc: bh,
    year: "2006",
    hoverText: "",
  },
  {
    title: 'Thriller',
    imageSrc: thriller,
    year: "1982",
    hoverText: "",
  },
  {
    title: 'Rage Against The Machine',
    imageSrc: ratm,
    year: "1992",
    hoverText: "",
  },
  {
    title: 'The Color And The Shape',
    imageSrc: color,
    year: "1997",
    hoverText: "",
  },
  {
    title: 'Continuum',
    imageSrc: continuum,
    year: "2006",
    hoverText: "",
  },
  {
    title: '21',
    imageSrc: adele,
    year: "2011",
    hoverText: "",
  },
  {
    title: 'OK Computer',
    imageSrc: okcomputer,
    year: "1997",
    hoverText: "",
  },
  {
    title: 'Dookie',
    imageSrc: dookie,
    year: "1994",
    hoverText: "",
  },
  {
    title: 'Enema Of The State',
    imageSrc: enema,
    year: "1999",
    hoverText: "",
  },
]

function Album(props) {
  return (
    <div>
      <div className="albumContainer">
        <div className="albumImage">
          <img src={props.imageSrc} alt="" />
          <div className="albumImageHover">
            <span>{props.hoverText}</span>
          </div>
        </div>
        <div className="albumTitle">
          <p>{props.title}</p>
        </div>
        <div className="albumYear">
          <p>{props.year}</p>
        </div>
      </div>
      <div className="albumBottomLine">
        <div className="albumBottomCircle">
          <div className="albumBottomSmallCircle">
          </div>
        </div>
      </div>
    </div>
  );
}

function AlbumsChronology(props) {
  return (
    <div className="albumChronologyContainer">
      {props.albums.sort((a, b) => a.year - b.year).map(album =>
        <Album key={album.title} title={album.title} imageSrc={album.imageSrc} year={album.year} hoverText={album.hoverText} />
      )}
      <YearDisplay />
    </div>
  );
}

function YearDisplay(props) {
  return (
    <div className="YearDisplay">
      1984
    </div>
  )
}

function Music(props) {
  return (
    <div className="Music">
      <Header theme={props.theme} toggleTheme={props.toggleTheme} />
      <div className="albumChronologyMainContainer">
        <div className="albumChronologyMainContainerTitle">
          <h2>A chronology of my favourites albums</h2>
          <svg className="albumChronologyMainContainerTitleSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 100 1440 180">
            <path fill="var(--background)" fillOpacity="1" d="M0,192L34.3,176C68.6,160,137,128,206,112C274.3,96,343,96,411,122.7C480,149,549,203,617,208C685.7,213,754,171,823,170.7C891.4,171,960,213,1029,240C1097.1,267,1166,277,1234,272C1302.9,267,1371,245,1406,234.7L1440,224L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
          </svg>
        </div>
        <AlbumsChronology albums={albums} />
      </div>
      <div className="project-desc music-page">
        <h2>Rockin' 1000</h2>
        <p>
          In 2018 I had the chance to be selected to play bass with Rockin' 1000 in the Stade de France on June 29th, 2019.
          Here's a link to watch the full show :
        </p>
        <div>
          <ReactPlayer max-width="100%" max-height="100%" className="music-yt-video"
            url='https://www.youtube.com/watch?v=pSN3MO0e4cY'
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Music;