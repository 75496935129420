import React from 'react';
import { Link } from "react-router-dom";
import "./projectbox.css"

const ProjectBox = (props) => {
    return (
        <div className="ProjectBox">
            <div className="project-box-img-container">
                <Link to={props.link}>
                    <img src={props.image} alt="" />
                </Link>
            </div>
            <h4 className="ProjectBoxTitle">{props.title}</h4>
        </div>
    );
}

export default ProjectBox;