import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectImage from '../components/ProjectImage';
import ProjectHeader from '../components/ProjectHeader';
import './page-style-sheet.css'

import blue from './projects-meta/TIPE/blue.bmp';
import red from './projects-meta/TIPE/red.bmp';
import red_coded from './projects-meta/TIPE/code4_4_red.bmp';
import red_decoded from './projects-meta/TIPE/decode_red.bmp';
import buildings from './projects-meta/TIPE/buildings.bmp';
import buildings2 from './projects-meta/TIPE/buildings2.bmp';
import buildings3 from './projects-meta/TIPE/buildings3.bmp';
import goose from './projects-meta/TIPE/goose.png';
import goose44 from './projects-meta/TIPE/goose44.bmp';
import goose62 from './projects-meta/TIPE/goose62.bmp';
import watch from './projects-meta/TIPE/watch.bmp';
import watch44 from './projects-meta/TIPE/watch44.bmp';
import watch62 from './projects-meta/TIPE/watch62.bmp';





const Steganography = (props) => {
    return (
        <div className="Steganography">
            <Header theme={props.theme} toggleTheme={props.toggleTheme}/>
            <ProjectHeader title="Steganography : How to Hide a Message in an Image" partners="Louis de Langalerie, Alexandre Bertin" duration="6 months / 2 hours a week" languages="Python" />

            <div className="project-desc">
                <p>This project is the 'TIPE' I've made with two other students during my "Classes Préparatoires".
                The aim of this project was to search for methods to hide an image (or a message) inside another image.
                We then tried to fix homegeneity issues we faced with some techniques.
                </p>

                <h2>Imaging with Python</h2>
                <p>
                    For this project, we worked on Python, and used the module Image to import and modify some images.
                    Each image was converted into an array where each coordinate was equal to the RGB value of its pixel on the image.
                    The RGB values of each pixel were stored in binary.
                </p>

                <h2>First Method (LSB 4-4)</h2>

                <p>
                    First, we have to notice that when we modify the least significant bits (LSB) of a byte,
                    its decimal value doesn't change too much. #METTRE PHOTO PIXELS
                    Given this, we first replaced the 4 least significant bits of each pixel of the hiding image with the 4 most significant bits of each pixel of the
                    image we wanted to hide.
                </p>
                <p>
                    Example :
                </p>
                <p style={{ textAlign: 'center' }}>
                    Pixel of the hiding image : <span style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>1001</span>0010
                </p>
                <p style={{ textAlign: 'center' }}>
                    Pixel of the image to hide : <span style={{ color: 'red', fontWeight: 'bold' }}>0111</span>0011
                </p>
                <p style={{ textAlign: 'center' }}>
                    Pixel of the result image : <span style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>1001</span><span style={{ color: 'red', fontWeight: 'bold' }}>0111</span>
                </p>

                <p>
                    To decode, we simply take the 4 LSB of each pixel and left shift them to get a byte.
                </p>
                <p>
                    Example :
                </p>
                <p style={{ textAlign: 'center' }}>
                    Pixel of the coded image : <span style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>1001</span><span style={{ color: 'red', fontWeight: 'bold' }}>0111</span>
                </p>
                <p style={{ textAlign: 'center' }}>
                    Pixel of the decoded image : <span style={{ color: 'red', fontWeight: 'bold' }}>0111</span>0000
                </p>

                <p>
                    Here is a concrete example : we are using very detailed images for this first try. We are hiding ImageB into ImageA. ImageC is the coded image, and ImageD is
                    the decoded one.
                </p>

                <div className="ImageFlexbox">
                    <ProjectImage image={blue} legend="ImageA : the hiding one" imgClass="imgTIPE" />
                    <ProjectImage image={red} legend="ImageB : the one to hide" imgClass="imgTIPE" />
                    <ProjectImage image={red_coded} legend="ImageC : the coded one" imgClass="imgTIPE" />
                    <ProjectImage image={red_decoded} legend="ImageD : the decoded one" imgClass="imgTIPE" />
                </div>

                <p>
                    Great ! It seems to work very well, we cannot distinguish the hiding image from the coded one, and neither the image to hide
                    from the decoded one. Well, not so fast, here is an other example that demonstrates the limits of this method :
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={buildings} legend="Initial image" imgClass="imgTIPE_2" />
                    <ProjectImage image={buildings2} legend="Coded image" imgClass="imgTIPE_2" />
                    <ProjectImage image={buildings3} legend="Zoom-in on the sky : if you move back enough, you may be able to see a face hided in this part" imgClass="imgTIPE_2" />
                </div>

                <p>
                    I think this example perfectly shows the issue we were facing : on the one hand, the main advantage of the LSB 4-4 method is that
                    very detailed images can easily hide others, and the decoded ones' quality will be excellent. On the other hand, images that contain 
                    homogeneous areas let portions of the hided image appear through them (a kind of jamming).
                </p>

                <h2>Reducing the number of LSB (LSB 6-2)</h2>
                <p>
                    Next step for us was to try to reduce the number of LSB, to see if the hiding image would be less modified visually.
                    Let's look at an example where we're trying to hide a watch picture inside of a goose one. 
                </p>
                <div className="ImageFlexbox">
                    <ProjectImage image={goose} legend="Initial image of the goose" imgClass="imgTIPE_2" />
                    <ProjectImage image={watch} legend="Initial image of the watch" imgClass="imgTIPE_2" />
                </div>
                <div className="ImageFlexbox">
                    <ProjectImage image={goose44} legend="Coded image in 4-4" imgClass="imgTIPE_2" />
                    <ProjectImage image={watch44} legend="Decoded image in 4-4" imgClass="imgTIPE_2" />
                </div>
                <div className="ImageFlexbox">
                    <ProjectImage image={goose62} legend="Coded image in 6-2" imgClass="imgTIPE_2" />
                    <ProjectImage image={watch62} legend="Decoded image in 6-2" imgClass="imgTIPE_2" />
                </div>
                <p>
                    Now it's harder to distinguish the hided image in the coded one. However, the quality of the decoded image is not very good.
                </p>
                <h2>Next Part : Split and Merge (coming soon)</h2>

            </div>


            <Footer />
        </div>
    );
}

export default Steganography;