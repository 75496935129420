import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProjectHeader from '../components/ProjectHeader';
import './page-style-sheet.css'





const Cod = (props) => {
    return (
        <div className="Cod">
            <Header theme={props.theme} toggleTheme={props.toggleTheme} />
            <ProjectHeader title="Challenge Open Data" partners="Benoît Doussin, Pierre Millot, Titouan Jouffray, Mathieu Valliccioni" duration="10 days" languages="HTML, CSS, JS" />

            <div className="project-desc">
                <p>
                    This project's aim was to create a website allowing visualisation of open data. We chose to display various data from European countries such as GDP, poverty rate, etc... and tried to correlate these values with the governments' political spectrums.
                    My main focus on this project was to make the application responsive and the interaction with users the most intuitive and pleasant as possible. It is also important to know that we only had 10 days to think and develop this web application.
                </p>
                <p>
                    Check out the application <a target="_blank" rel="noopener noreferrer" href="http://lig-tdcge.imag.fr/~sylvain/opendata/2020-2021/doussinb-esterlit-jouffrat-millotp-valliccm/index.html">here</a> !
                </p>
            </div>

            <Footer />
        </div>
    );
}

export default Cod;