import React from 'react';
import Header from './components/Header';
import ProjectBoxes from './components/ProjectBoxes';
import Footer from './components/Footer';

//import test from '../meta/test.png';
import connexes from '../meta/connexes.png';
import troisd from '../meta/3D.png';
import javaprojet from '../meta/java.png';
import lg from '../meta/lg.png';
import tipe from '../meta/tipe.jpg'
import cod from '../meta/cod.png'
import PageHeader from './components/PageHeader';


const projects = [
  {
    title: 'Steganography / Split and Merge',
    image: tipe,
    id: 0,
    link: "/projects/steganography",
  },
  {
    title: 'Reasearch of Connected Points in a 2-Dimensional Surface Area',
    image: connexes,
    id: 2,
    link: "/projects/research-connected",
  },
  {
    title: 'Multi-Agent Systems',
    image: javaprojet,
    id: 1,
    link: "/projects/ma-systems",
  },
  {
    title: '3D Graphics - Sea and Underwater',
    image: troisd,
    id: 3,
    link: "/projects/3d-graphics",
  },
  {
    title: 'Online Loups-Garous Game',
    image: lg,
    id: 4,
    link: "/projects/web-lg",
  },
  {
    title: 'Challenge Open Data',
    image: cod,
    id: 5,
    link: "/projects/cod",
  },
  /*{
    title: 'JPEG Decoder',
    image: test,
    id: 5,
    link: "/projects/jpeg-decoder"
  }, 
  {
    title: 'Compiler Development',
    image: test,
    id: 6,
    link: "/projects/gl",
  },*/
]

const Projects = (props) => {
  return (
    <div className="Projects">
      <Header theme={props.theme} toggleTheme={props.toggleTheme} />
      <PageHeader title="School Projects" />
      <ProjectBoxes projects={projects}/>
      <Footer />
    </div>
  );
}

export default Projects;