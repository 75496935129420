import React from 'react';
import Header from "./components/Header";
import Footer from './components/Footer';
import './home.css'

import illustration_light from '../meta/home.png'
import illustration_dark from '../meta/home_dark.png'

function Home(props) {
    return (
        <div className="Home">
            <Header home='active' theme={props.theme} toggleTheme={props.toggleTheme} />
            <div className="welcome">
                <div className="welcome-text">
                    <h1>Welcome to my online portfolio ! Click on the top-right menu to discover my projects !</h1>
                </div>
                <div className="welcome-img">
                    <img src={props.theme === 'light' ? illustration_light : illustration_dark} alt="" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Home;