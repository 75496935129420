import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import "./gameboy.css"

import zelda1 from '../../meta/gameboy/zelda1.gif';
import zelda2 from '../../meta/gameboy/zelda2.gif';
import zelda3 from '../../meta/gameboy/zelda3.gif';
import zelda4 from '../../meta/gameboy/zelda4.gif';
import zelda5 from '../../meta/gameboy/zelda5.gif';
import zelda6 from '../../meta/gameboy/zelda6.gif';
import zelda7 from '../../meta/gameboy/zelda7.gif';
import zelda8 from '../../meta/gameboy/zelda8.gif';
import GameBoyGif from '../components/GameBoyGif';

const chapters = [
    {
        index: 0,
        chapterLetter: "a",
        chapterTitle: "Animating a character",
        paragraphs: [
            <p>The first thing we developed in our game was the animations of our main hero. We can split them in three categories : the walking animations, the iteracting animations and the attacking animations.I am not going to describe here how a Game Boy works, if you are interested in it, I recommend you to check out <a href='https://blog.flozz.fr/2019/09/18/developpement-gameboy-1bis-re-hello-world/'>this blog</a> where we learnt everything we needed to before starting coding.</p>,
            <p>This being said, our hero (a sprite made of two 8x16 tiles) was given a lot of animations tiles stored in the VRAM of the console, and we changed the ones displayed on the screen according to his position in his environment and the buttons clicked by the user (concretely A to attack and UP, DOWN, LEFT and RIGHT to move).</p>,
            <div className="GifFlexbox">
                <GameBoyGif image={zelda1} legend="Our hero moving in a room" />
                <GameBoyGif image={zelda2} legend="Our hero interacting with the walls" />
                <GameBoyGif image={zelda3} legend="Our hero attacking with his sword" />
            </div>
        ]
    },
    {
        index: 1,
        chapterLetter: "b",
        chapterTitle: "Moving into the environment",
        paragraphs: [
            <p>
                Now that our hero was able to move, we thought it would be much more interesting if he could visit
                different rooms and discover the world we put him into. Rendering the illusion of crossing over a
                room turned out to be a little more challenging than we first thought. Indeed, when our hero goes to
                a neighbouring room, not only are we changing the background tiles of the room, we are also moving
                our hero's sprites to the opposite side to create a smooth transition. To go further, we tried to
                develop a scrolling effect, which appeared to be very difficult due to the size of the Game Boy's
                background VRAM. Then, we also developed a stairs system, as well as doors to be able to move between
                different sections of the world.
            </p>,
            <div className="GifFlexbox">
                <GameBoyGif image={zelda5} legend="Scrolling when crossing a room" />
                <GameBoyGif image={zelda4} legend="Our hero using stairs" />
                <GameBoyGif image={zelda7} legend="Our hero discovering new lands" />
            </div>
        ]
    },
    {
        index: 2,
        chapterLetter: "c",
        chapterTitle: "Interacting with objects",
        paragraphs: [
            <p>
                What would be a game without interactions with its environment ? In our little game, the main resources are
                money and life. The amount of each one our hero owns is displayed on the 'window layer',
                at the bottom of the screen. He can pick up rupees to increase his money or hearts to revitalise himself.
                To come closer with the original game, we decided to add chests, which would contain some items like rupees,
                hearts, dungeon keys, but also non-consumable ones like new weapons or tools. Implementing chests mean
                implementing text to be displayed on the screen to inform the player what is contained inside.
                For that, instead of storing the whole alphabet in the VRAM, we decided to give text boxes specific places in
                the VRAM, and to dynamically change the tiles directly in the VRAM (check out the example below).
            </p>,
            <div className="GifFlexbox">
                <GameBoyGif image={zelda6} legend="Our hero opening a chest" />
                <GameBoyGif image={zelda8} legend="VRAM being dynamically updated instead of storing the whole alphabet" />
            </div>
        ]
    }
]

function GameboyPart(props) {
    return (
        <div className="GameboyPart">
            <Header theme={props.theme} toggleTheme={props.toggleTheme} />

            <div className="GameBoy-ChapterContainer">
                <div className="GameBoy-Menu">
                    <div className="GameBoy-MenuLink">
                        <Link className={props.partA} to='/projects/gameboy-a'>- Animating a character</Link>
                    </div>
                    <div className="GameBoy-MenuLink">
                        <Link className={props.partB} to='/projects/gameboy-b'>- Moving into the environment</Link>
                    </div>
                    <div className="GameBoy-MenuLink">
                        <Link className={props.partC} to='/projects/gameboy-c'>- Interacting with objects</Link>
                    </div>
                </div>
                <div className="GameBoy-desc">
                    <div className="chapterTitleContainer">
                        <p className="chapterNumber">{props.chapter.chapterLetter} </p>
                        <h2>{props.chapter.chapterTitle}</h2>
                    </div>
                    {props.chapter.paragraphs.map(paragraph =>
                        paragraph
                    )}

                </div>
            </div>
            <Footer />
        </div>
    );
}

function GameboyPartA(props) {
    return (
        <div className="GameboyPartA">
            <GameboyPart theme={props.theme} toggleTheme={props.toggleTheme} chapter={chapters[0]} partA="active" />
        </div>
    );
}

function GameboyPartB(props) {
    return (
        <div className="GameboyPart">
            <GameboyPart theme={props.theme} toggleTheme={props.toggleTheme} chapter={chapters[1]} partB="active" />
        </div>
    );
}

function GameboyPartC(props) {
    return (
        <div className="GameboyPart">
            <GameboyPart theme={props.theme} toggleTheme={props.toggleTheme} chapter={chapters[2]} partC="active" />
        </div>
    );
}

export { GameboyPartA, GameboyPartB, GameboyPartC };